table {
  &.person_data {
    width: 126%;
    font-size: 1.2em;
    margin-bottom: 2.35em;
  }

  &.person_data td.firstCol {
    text-align: right;
    width: 25%;
    padding-right: 1em;
  }

  &.person_data td.secondCol {
    width: 70%;
    font-family: Arial, Helvetica, sans-serif;
  }

  &.display,
  &.simpleTable {
    td {
      padding: 0.75em;
      vertical-align: middle;
      font-family: Arial, Helvetica, Verdana, sans-serif;
      text-align: left;
    }

    tr {
      &.selected {
        background: #ddff66;

        td {
          box-shadow: 0 1px 0 #9c0 inset, 0 -1px 0 #9c0 inset;

          &:first-child,
          &:last-child {
            box-shadow: 0 1px 0 #9c0 inset, 0 -1px 0 #9c0 inset, -1px 0 0 #9c0 inset;
          }
        }
      }

      &.semiSelected {
        background: #ddff66;
      }

      &.overdue.selected {
        td {
          box-shadow: 0 1px 0 #f90 inset, 0 -1px 0 #f90 inset;

          &:first-child,
          &:last-child {
            box-shadow: 0 1px 0 #f90 inset, 0 -1px 0 #f90 inset, 1px 0 0 #f90 inset;
          }
        }
      }
    }

    transition: all .2s linear;

    tr:hover {
      cursor: pointer;
    }
  }

  &.display {
    thead {
      th {
        padding: 0.75em;
        vertical-align: middle;
        font-family: Arial, Helvetica, Verdana, sans-serif;
        text-align: left;
      }
    }

    td img {
      margin-right: 3px;
    }

    tr td:last-child img {
      opacity: 0;
      transition: all .2s linear;
    }

    tr:hover td:last-child img {
      opacity: 1;
    }
  }

  &.simpleTable {
    padding: 0.75em;
    vertical-align: middle;
    font-family: Arial, Helvetica, Verdana, sans-serif;
    text-align: left;
  }

  &.simpleTable {
    width: 100%;

    thead,
    tfoot {
      font-weight: bold;
    }
  }

  /* --- 3-Monats-Übersicht --- */

  &.months-overview {
    width: 90%;
    margin-left: 5%;

    td, th {
      text-align: right;
      background: #fff;

      &.firstCol {
        text-align: left;
      }
    }

    tr.headline td {
      padding-top: 2em;
      font-weight: bold;
    }

    tr.bold td {
      font-weight: bold;
    }

    th {
      padding-top: 2em;
      font-size: 1.25em;
    }

    tr.indented td.firstCol {
      padding-left: 2em;
    }

    tr.indented:nth-child(odd) td {
      background: #f6f6f6;
    }

    tr.sum td {
      border-top: 1px solid black;
      font-weight: bold;
    }

    .dataTables_wrapper {
      background: #fff;
    }
  }

  td a:hover {
    text-decoration: underline;
    color: #00F;
  }

  tr a:hover {
    color: #00F;
  }

  td div {
    line-height: 1.35em;
  }

  td.comments {
    word-wrap: break-word;
    overflow: hidden;
    max-width: 25em;
    line-height: 1.2em;
  }
}