@import "../vars";

@mixin max {
  @media screen {
    @media (max-width: #{$mobileMax}),
    (max-width: #{$tabletMax}),
    (-webkit-min-device-pixel-ratio: #{$devicePixelRatio}),
    (min-resolution: #{$retinaDpi}),
    (min-resolution: #{$retinaMinResolution}) {
      @content
    }
  }
}

@mixin over2000w {
  @media screen and (min-width: 2000px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 411px) {
    @content
  }
}

@mixin tablet {
  @media screen and (min-width: 412px) {
    @content
  }
}

@mixin smallTablet {
  @media screen and (min-width: 600px) and (max-width: 1099px) {
    @content
  }
}

@mixin bigTablet {
  @media screen and (min-width: 1100px) and (max-width: 1199px) {
    @content
  }
}

@mixin smallDesktop {
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    @content
  }
}

@mixin mediumDesktop {
  @media screen and (min-width: 1400px) and (max-width: 1599px) {
    @content
  }
}

@mixin bigDesktop {
  @media screen and (min-width: 1600px) and (max-width: 1999px) {
    @content
  }
}