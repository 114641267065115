@import "mixins/mediaQueries";

#main {
  width: 100%;
  margin-top: 3.125rem;
  display: block;

  min-height: 100vh;

  section.widget {
    background: #F6F6F6;
    border: 1px solid #000;
    box-shadow: unset;
    float: left;
    margin: 1%;

    @include max {
      &.column-1, &.column-2, &.column-3, &.column-4, &.column-5, &.column-6, &.column-7, &.column-8 {
        min-width: 95%;
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }
  }

  section.widget::after {
    content: "";
    width: 5em;
    display: block;
    position: absolute;
    top: 100%;
    height: 5em;
  }

  section.collapsible header a.dropdown {
    display: block;
    height: 16px;
    width: 16px;
    background: url(../../images/office/dropdown_arrow.png);
    position: absolute;
    right: 10px;
    top: 10px;
    transform: rotate(360deg);
    transition: all 0.5s;
  }

  section.collapsed header a.dropdown {
    transform: rotate(180deg);
  }

  section.widget {
    header {
      height: 36px;
      background: #000;
      color: #FFF;
      position: relative;

      h3 {
        font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
        font-size: 2em;
        padding-top: 6px;
        text-align: center;
      }
    }

    &.draggable {
      header:hover {
        cursor: move;
      }
    }

    &.loginbox,
    &.logoutbox {
      width: 320px;
      margin: 220px auto 0;
      float: none;
    }

    &.loginbox .padded_content {
      padding: 30px 45px;
    }

    .padded_content {
      padding: 20px;
    }
  }
}