.selectbox {
  display: inline-block;
  *display: inline;
  font-size: 1em;
  position: relative;
  vertical-align: middle;
  zoom: 1;
  width: 100%;

  .display {
    padding: 2px 28px 2px 7px;
    background: #fff;
    border: 1px solid #dddddd;
    color: #000;
    cursor: default;
    display: block;
    font-weight: normal;
    height: 1.46em;
    line-height: 1.46em;
    overflow: hidden;
    outline: none;

    position: relative;
    text-decoration: none;
    margin: 2px 0;
  }

  &.disabled {
    .display {
      color: #999;
    }

    .display .text {
      display: inline-block;
      *display: inline;
      zoom: 1;
    }

    .display .arrow_btn {
      background: #EEEEEE;
      border-left: 1px solid #DDDDDD;
      display: block;
      height: 100%;
      padding: 1px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 20px;
    }

    .display .arrow_btn .arrow {
      content: "";
      background: transparent url(../../images/office/dropdown_arrow.png) no-repeat center center;
      display: inline-block;
      height: 22px;
      left: 2px;
      position: absolute;
      top: 0;
      width: 19px;
      zoom: 1;
    }

    .display.active .arrow_btn {
      background-color: #DDDDDD;
      border-color: #CCCCCC
    }

    .value {
      display: none;
    }

    .display.active {
      border-color: #CCCCCC;
    }
  }

  &.items {
    background: #fff;
    border: 1px solid #DDDDDD;
    display: block;
    font-size: 1em;
    list-style-type: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 0 1px 0;
    z-index: 99999;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);

    .disabled > .item {
      opacity: 0.5
    }

    .hover > .item {
      background-color: #0099FF;
      color: #fff;
    }

    .value {
      display: none;
    }

    .optgroup .items {
      border: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

    .optgroup > .label {
      cursor: default;
      font-weight: bold;
      line-height: 1em;
    }

    .optgroup .items li > .item {
      padding-left: 1em;
    }
  }

  li {
    display: block;
    margin: 0;
    padding: 0;

    &.first {
      margin-top: 0;
    }

    & > .item {
      color: #000;
      cursor: default;
      display: block;
      padding: 7px;
      text-decoration: none;

      & > .text {
        display: inline-block;
        *display: inline;
        zoom: 1;
      }
    }
  }

  li > .item,
  .selected > .item {
    background-color: #ddd
  }

  .round_sb.display {
    padding: 2px 26px 2px 5px;
    border-radius: 5px;

    .arrow_btn {
      right: 2px;
      top: 2px;
      border-radius: 3px;
    }
  }

  &.round_sb.items {
    border-radius: 5px;

    & > li > a {
      padding: 2px 4px;
    }

    & > li.first > .item {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.round_sb.items,
  &.round_sb.items > li.last > .item {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.round_sb.items > li > .label {
    line-height: 2.08em;
    padding: 2px 4px;
  }

  &.round_sb.items > li > .items li > .item {
    padding: 2px 4px 2px 10px;
  }

  .display.fixed_width {
    width: 100%;
    padding: 0 24px 0 3px;
  }

  .display.fixed_width2 {
    width: 100%;
    padding: 0 24px 0 3px;

    &.items {
      width: 300px; /* width of display text plus the padding (60 + 27) = 87 */
    }
  }
}