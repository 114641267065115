/* Formulare: */
input, select, textarea {
  padding: 0.5em;
  margin: 0;
  border: 1px solid #DDD;
  line-height: 1em;
  background-color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
}

h3 input {
  padding: 0;
}

select {
  padding-left: 0.25em;
}

fieldset, legend {
  border: 1px solid #AAA;
  padding: 1em;
  margin: 0 0 1em;
  background: #FFF;
}

legend {
  font-weight: bold;
  padding: 1em 1.5em;
}

fieldset.step {
  position: relative;
  z-index: 1;
}

/* Fehler in Formularen:
 * Formularelemente in der selben Zeile sollten immer in p-Tags eingeschlossen werden.
 * Wenn die Eingabe nicht validiert, kann dem entsprechenden p-Tag die Klasse "validationError"
 * zum Highlighten gegeben werden. Zusätzlich sollte am Anfang des Formulars ein p.error-Tag
 * mit der entsprechenden Fehlermeldung eingefuegt werden.
*/

.validationError {
  input, select, textarea, div.cleditorMain {
    border-color: #C00;
  }

  label {
    color: #C00;
  }
}

button {
  &#newPerson,
  &#existingPerson {
    width: 45%;
    height: 30px;
  }

  &#newPerson:before {
    content: url(../../images/icons/newcontact.png);
    position: relative;
    top: 4px;
    left: 6px;
    float: left;
    margin-right: 16px;
  }

  &#existingPerson:before {
    content: url(../../images/icons/user.png);
    position: relative;
    top: 4px;
    left: 6px;
    float: left;
    margin-right: 16px;
  }

  /*--- Mail-Accounts --- */
  &#mailInfoButton {
    float: right;
  }

  &.cancel {
    color: #911;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1em;
    margin: 0;
    padding: 0.5em;

    &:focus {
      background: #FDD;
      border-color: #DBB;
    }
  }
}

button,
input {
  &.loading {
    background: url(../../images/loading.gif) no-repeat 50% 50% !important;
    color: transparent !important;
  }
}

input {
  &[type="submit"] {
    color: #191;
  }

  &[type="text"]:focus {
    background: #EEE;
    border-color: #CCC;
  }

  &.loading {
    &.loading-right {
      background-position: 95% 50% !important;
    }

    &.not-transparent {
      color: #999 !important;
    }
  }
}

.okayButton {
  color: #191 !important;
}

input[type="submit"]:focus,
.okayButton:focus {
  background: #DFD;
  border-color: #BDB;
}

optgroup {
  background: #EEE;
  color: #333;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;

  option {
    background: #FFF;
    color: #000;
    font-weight: normal;
  }
}

input,
select,
textarea {
  &[disabled] {
    background: #F6F6F6;
    border-color: #F6F6F6;
    color: #000;
  }
}

form.searchform {
  margin-bottom: 1em;

  input[type=search] {
    width: 75%;
  }

  input[type=submit] {
    width: 20%;
    margin-left: 1%;
  }
}

form button.smallbutton {
  width: 4.5%;
  margin-left: 0.5%;
  vertical-align: top;
}

table button.smallbutton:first-child {
  margin-left: 2%;
}

/* form.person_data */
form.person_data {
  margin-bottom: 2em;

  input {
    width: 70%;
  }
}

button.edit_person_data {
  float: right;
}

form {
  &#loginform {
    input {
      width: 100%;
    }

    label {
      display: block;
      width: 100px;
      padding-bottom: 3px;
    }

    p {
      margin-bottom: 8px;
    }

    input[type="submit"] {
      width: 100%;
      margin-top: 10px;
    }
  }

  &#sendmail {
    input,
    select {
      width: 100%;
      padding: 0.35em;
      margin: 0.2em 0 0.6em;
    }

    input[type="submit"] {
      width: 58%;
      float: left;
    }

    button#cancel {
      float: left;
      width: 40%;
      margin-right: 2%;
      text-align: center;
      color: #911;
    }
  }

  &#customer_notice {
    h3 {
      margin-top: 1em;
    }

    textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: 8em;
      min-height: 8em;
      margin-bottom: 0.6em;
    }

    input {
      float: right;
      width: 40%;
    }
  }

  &#customer_task {
    input {
      width: 39.7em;
    }

    #box_shadows {
      display: block;
      width: 20%;
      margin-top: 0.6em;
    }

    input[type="text"],
    select {
      float: left;
      width: 30%;
    }
  }

  &#customer_transaction {
    margin-top: 1.5em;
  }

  &.person_data button#cancel {
    border: 1px solid #ccc;
    height: 2.5em;
    width: 24.5%;
    margin-left: 25%;
    margin-right: 0.5%;
    color: #911;
  }
}

input[type="submit"] {
  #createcontact,
  #updatecontact {
    border: 1px solid #ccc;
    height: 2.5em;
    width: 44.5%;
  }
}

form#sendmail textarea {
  border-bottom: 1px solid #DDD;
}