$folderWidth: 13.125rem;
$zIndex: 12;
$borderColor: #ddd;
$fontSize: .75rem;
$topDistance: 2.875rem;
$bottomDistance: 1.25rem;
$borderRadius: .188rem;
$mailHeaderHeight: 2rem;
$mailOptionsHeight: 3.25rem;
$tableHeaderBackground: #223;

$defaultBoxShadow: 0 2px 4px rgba(0, 0, 0, 0.5);