@import "mixins/mediaQueries";
@import "../vars";


#wrapper {
  header {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 1500;
    background: linear-gradient(to bottom, #333 40%, #000 100%);
    justify-content: space-between;

    a {
      color: #FFF;
    }

    position: fixed;

    box-shadow: $defaultBoxShadow;

    top: 0;

    .header-left,
    .header-right {
      display: flex;
    }

    .header-right {
      flex-direction: row-reverse;

      #user {
        .edit_user {
          margin-right: 10px;
        }

        a {
          display: block;
          float: left;

          &.logout {
            .icon {
              background-position: -128px 0;
            }

            &:before {
              content: "L";
              top: 0.17em;
            }

            &:hover .icon {
              background-position: -128px -32px;
            }
          }
        }

        .logout {
          position: relative;
          font-size: 287%;
          width: 1.4em;
          height: 46px;

          @media screen and (max-width: 599px),
          screen and (max-width: 1199px) and (-webkit-min-device-pixel-ratio: 2),
          screen and (max-width: 1199px) and (min-resolution: 2dppx) {
            height: 39px;
          }
        }

        .icon {
          width: 32px;
          height: 32px;
          margin: 7px;
          float: left;
          display: none;
        }

        p {
          display: block;
          float: left;
          padding-left: 5px;
          padding-right: 5px;
          position: relative;
          top: 3px;
        }
      }

      #searchResultBox {
        position: absolute;
        right: -6.25rem;
        max-height: 31.25rem;
        overflow: auto;
        width: 25rem;
        box-shadow: 0 0.25em 0.33em rgba(0, 0, 0, 0.5);

        /* Search results */
        .searchResultCategoryList {
          color: black;
          cursor: pointer;

          .searchResultCategory {
            article {
              display: block;

              h1 {
                padding: 0.25em 0.5em;
                font-size: 100%;
                margin: 0;

                cursor: pointer;

                background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);

                border: 1px solid #aaa;
                color: #333;
                text-shadow: 1px 1px 0 #fff;
                font-weight: bold;
              }

              .searchResultItemList {
                background-color: white;
                overflow: hidden;

                transition: height 0.3s ease;

                .searchResultItem {
                  background: #fff;

                  &:nth-child(odd) {
                    background: #eee;
                  }

                  h1 {
                    padding: 0.25em 0.5em 0;

                    border: none;
                    background: none;
                  }

                  p {
                    color: #000;
                    padding: 0 0.5em 0.25em;
                    margin-bottom: 0px;
                  }

                  article:hover {
                    background-color: rgba(230, 255, 64, 0.66);
                  }
                }

                .searchResultListItem {
                  .searchType-service {
                    .price {
                      float: right;
                      font-weight: normal;
                    }
                  }

                  .searchType-bankAccount .label,
                  .searchType-appointment .label {
                    width: 4em;
                    display: inline-block;
                  }

                  .searchType-appointment .label {
                    width: 5em;
                  }

                  .searchType-activity h1 {
                    padding-bottom: 0.2em;
                  }

                  .searchType-activity .icon {
                    font-family: IcoMoon, sans-serif;
                    font-size: 10px;
                  }

                  .searchResultItem {
                    .oldID, .role, .sector {
                      float: right;
                      font-weight: normal;
                    }

                    .annotation {
                      float: right;
                    }
                  }
                }
              }
            }
          }

          .searchResultCategory:last-of-type .searchResultItemList,
          .searchResultItem:last-of-type {
            border-bottom: none;
          }
        }
      }
    }

    nav {
      ul {
        li,
        li a {
          height: 46px;
          width: 4.0726em; /* 1.4em * 290.9%; see header nav ul li a */
          display: block;
          overflow: hidden;

          .icon {
            width: 32px;
            height: 32px;
            margin: 7px;
            float: left;
            display: none;
          }
        }

        li {
          float: left;

          &.hover,
          &:hover {
            overflow: visible;
          }

          &.description {
            font-size: 1.25em;
            line-height: 42px;
            margin-left: 1em;
            display: none;
            color: white;
            width: auto;

            @media screen and (max-width: 1020px) {
              width: 0;
            }
          }

          &.home {
            a {
              .icon {
                background-position: -32px 0;
              }

              &:hover,
              &.active {
                .icon {
                  background-position: -32px -32px;
                }
              }
            }
          }

          &.todo a .icon {
            background-position: -160px 0px;
          }

          &.calendar a .icon {
            background-position: -64px 0px;
          }

          &.designs a .icon {
            background-position: -96px 0px;
          }

          &.devtools a .icon {
            background-position: -384px 0px;
          }

          &.people a .icon {
            background-position: 0 0;
          }

          &.presentations a .icon {
            background-position: -256px 0px;
          }

          &.mailaccounts a .icon {
            background-position: -288px 0px;
          }

          &.webmail a .icon {
            background-position: -512px 0px;
          }

          &.domains a .icon {
            background-position: -192px 0px;
          }

          &.devtodo a .icon {
            background-position: -352px 0px;
          }

          &.templates a .icon {
            background-position: -448px 0px;
          }

          &.invoices a .icon {
            background-position: -224px 0px;
          }

          &.newsletter a .icon {
            background-position: -480px 0px;
          }

          a {
            height: 48px;
            text-decoration: none;
            color: #FFF;

            position: relative;
            font-size: 290.9%; /* 32px */
            width: 1.4em;
            text-align: center;

            p {
              display: none;
            }
          }

          .date {
            display: block;

            top: 1.6em;
            font-size: 37.5%;
            font-weight: bold;
            width: 100%;
            text-align: center;
            position: relative;
          }

          a:before {
            top: 0.17em
          }
        }

        ul {
          color: #FFF;
          z-index: 999;
          background: transparent;

          box-shadow: 0 2px 2px #000;
          margin-top: -5px;
          padding-top: 5px;

          li {
            float: none;
            background: #222;
          }

          li:first-child,
          &.double li:nth-child(2) {
            background: linear-gradient(to bottom, #111111 0%, #222222 10%);
          }

          li:last-child,
          &.double li:nth-last-child(2) {
            background: linear-gradient(to bottom, #222222 40%, #111111 100%);
          }

          li:first-child:last-child,
          &.double li:first-child:nth-last-child(2),
          &.double li:nth-child(2):last-child {
            background: linear-gradient(to bottom, #111111 0%, #222222 10%, #222222 40%, #111111 100%);
          }

          &.double li:nth-last-child(2):nth-child(2n) {
            background: #222;
          }

          &.double li:last-child:nth-child(2n+1) {
            width: 8.1452em;
          }

          &.double li:last-child:nth-child(2n+1) > a {
            width: 2.8em;
          }
        }
      }
    }
  }

  #main header {
    z-index: 1000;

    h3 {
      margin-left: 1rem;
    }
  }
}


header nav ul li.todo a:hover .icon, header nav ul li.todo a.active .icon {
  background-position: -160px -32px;
}

header nav ul li.calendar a:hover .icon, header nav ul li.calendar a.active .icon {
  background-position: -64px -32px;
}

header nav ul li.designs a:hover .icon, header nav ul li.designs a.active .icon {
  background-position: -96px -32px;
}

header nav ul li.people a:hover .icon, header nav ul li.people a.active .icon {
  background-position: 0px -32px;
}

header nav ul li.presentations a:hover .icon, header nav ul li.presentations a.active .icon {
  background-position: -256px -32px;
}

header nav ul li.mailaccounts a:hover .icon, header nav ul li.mailaccounts a.active .icon {
  background-position: -288px -32px;
}

header nav ul li.webmail a:hover .icon, header nav ul li.webmail a.active .icon {
  background-position: -512px -32px;
}

header nav ul li.domains a:hover .icon, header nav ul li.domains a.active .icon {
  background-position: -192px -32px;
}

header nav ul li.devtodo a:hover .icon, header nav ul li.devtodo a.active .icon {
  background-position: -352px -32px;
}

header nav ul li.devtools a:hover .icon, header nav ul li.devtools a.active .icon {
  background-position: -384px -32px;
}

header nav ul li.templates a:hover .icon, header nav ul li.templates a.active .icon {
  background-position: -448px -32px;
}

header nav ul li.invoices a:hover .icon, header nav ul li.invoices a.active .icon {
  background-position: -224px -32px;
}

header nav ul li.newsletter a:hover .icon, header nav ul li.newsletter a.active .icon {
  background-position: -480px -32px;
}

header nav ul li a:before,
header nav ul li a:after,
header #user a.logout:before {
  font-family: IcoMoon, sans-serif;
  font-style: normal;
  speak: none;
  position: absolute;
  top: 0.22em;
  left: 0.2em;
}

header nav ul li a:hover,
header nav ul li a.active,
header nav ul li a.hover {
  color: #333 !important;
  background: linear-gradient(to bottom, #fcfcfc 40%, #dddddd 100%);
}

#wrapper {
  header {
    @include max {
      nav ul li {
        height: 39px;

        a {
          height: 39px;

        }
      }
    }
  }
}