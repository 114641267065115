@import "includes/mixins/mediaQueries";

@import "includes/keyframes";
@import "includes/fonts";
@import "includes/header";
@import "includes/footer";

/* --- Layout --- */

html {
  min-height: 100%;

  body {
    color: #000;
    font-size: 1rem;

    @include over2000w {
      font-size: .813rem;
    }

    @include bigDesktop {
      font-size: .75rem;
    }

    @include mediumDesktop {
      font-size: .688rem;
    }

    @include max {
      font-size: 9px;
      min-width: 0;
    }

    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    height: 100%;

    div.loading-wrapper {
      transition: opacity .25s;

      &.loading {
        opacity: 0.5;

        .throbber {
          visibility: visible;
        }

        #wrapper {
          visibility: hidden;
        }
      }
    }

    p,
    div.line {
      line-height: 1.6em;
      margin-bottom: 1em;
    }
  }
}

.widget_content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: .5em;
    line-height: 1.25em;
  }
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.3em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-weight: bold;
}

a {
  text-decoration: none;
}

hr {
  height: 0;
  border: none;
  border-top: 1px solid #dddddd;
  margin: 1em 0;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: bottom;
}

input:focus {
  outline: 0; /* fix chrome input */
}

img.image-loader {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

@import "includes/main";

.offscreen {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

::-webkit-input-placeholder {
  color: #777;
}

:-moz-placeholder {
  color: #777;
}

:-ms-input-placeholder {
  color: #777;
}

:focus::-webkit-input-placeholder {
  color: #bbb;
}

:focus:-moz-placeholder {
  color: #bbb;
}

:focus:-ms-input-placeholder {
  color: #bbb;
}

.disabledButton {
  opacity: 0.5;

  filter: grayscale(100%) brightness(-10%);
}

.iconFont {
  font-family: IcoMoon, sans-serif;
  font-style: normal;
}

/* --- Widget-Raster --- */

section.widget {
  &.column-1 {
    width: 10%;
    min-width: 10%;
  }

  &.column-2 {
    width: 22.5%;
    min-width: 22.5%;
  }

  &.column-3 {
    width: 35%;
    min-width: 35%;
  }

  &.column-4 {
    width: 47.5%;
    min-width: 47.5%;
  }

  &.column-5 {
    width: 60%;
    min-width: 60%;
  }

  &.column-6 {
    width: 72.5%;
    min-width: 72.5%;
  }

  &.column-7 {
    width: 85%;
    min-width: 85%;
  }

  &.column-8 {
    width: 97.5%;
    min-width: 97.5%;
  }

  &.centered {
    margin: 60px auto !important;
    float: none !important;
  }

  &.fixedTextSize {
    min-width: 60em;
    max-width: 90em;

    .widget_content {
      font-size: 12px;
    }
  }

  header {
    box-shadow: none !important;
  }
}

.display-none-start {
  display: none;
}

/* --- Login & Logout-Box --- */

div.errorBox {
  width: 320px;
  margin: 20px auto 0;
}

/* --- Schnellstart-Liste auf der Startseite --- */

ul.quickstart {
  li {
    display: inline-block;
    width: 32%;
    margin: 0.5% 0.25%;

    a.button.newPerson:before {
      content: url(../../images/icons/newcontact.png);
      position: relative;
      top: 5px;
    }

    a.button.newPresentation:before {
      content: url(../../images/icons/projection_screen.png);
      position: relative;
      top: 5px;
    }

    a.button.newDate:before {
      content: url(../../images/icons/clock_add.png);
      position: relative;
      top: 5px;
    }

    a.button.newService:before {
      content: url(../../images/icons/current-work.png);
      position: relative;
      top: 5px;
    }

    a.button.sendMail:before {
      content: url(../../images/icons/email.png);
      position: relative;
      top: 5px;
    }

    a.button.financialOverview:before {
      content: url(../../images/icons/statistics.png);
      position: relative;
      top: 5px;
    }

    a.button.editUser:before {
      content: url(../../images/icons/user.png);
      position: relative;
      top: 5px;
    }

    a.button.orderDomain:before {
      content: url(../../images/icons/globe_add.png);
      position: relative;
      top: 5px;
    }

    a.button.newMailaccount:before {
      content: url(../../images/icons/mailbox.png);
      position: relative;
      top: 5px;
    }

    a.button.lastInvoice:before {
      content: url(../../images/icons/money_euro.png);
      position: relative;
      top: 5px;
    }

    a.button.logout:before {
      content: url(../../images/icons/logout.png);
      position: relative;
      top: 5px;
    }

    a.button {
      display: block;
      margin: 0;
      width: 100%;
    }
  }
}


form#notes textarea {
  width: 100%;
  margin-bottom: 1em;
}

#notes {
  padding: 1px 1px 1px;
  position: relative;
  border: 1px solid #ccc;
  background: linear-gradient(to bottom, #DFE8EC 0%, white 8%) 0 64px;

  background-size: 100% 20px;

  &::before {
    content: "";
    z-index: -1;
    margin: 0 1px;
    height: 10px;
    position: absolute;
    bottom: -3px;
    left: 0;
    background: white;
    border: 1px solid #B5B5B5;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
  }

  .notepad {
    background: transparent;
    border: 0;
    font-size: 16px;
    line-height: 20px;
    height: 300px;
  }
}

/* Optinsleiste in Widgets */
ul.toolbar {
  margin: 0 20px;

  li {
    float: left;
    border: none;

    a {
      padding: 0.5em;

      &.deactivated {
        color: #C0C0C0;
        opacity: 0.5;
        cursor: default;
      }

      @media screen and (max-width: 1279px) {
        &:before {
          display: block;
          left: 0 !important;
          top: 4px !important;
          margin-bottom: 0.25em;
        }
      }
    }
  }
}

/* --- Liste mit Options-Buttons --- */
ul.options {
  li {
    border: 1px solid #CCC;
    border-top: none;
    text-align: center;

    a {
      padding: 1em;
    }

    a:hover {
      background: #000;
      color: #FFF;
    }

    li:first-child {
      border-top: 1px solid #CCC;
    }
  }

  &.horizontal {
    padding: 20px;
    margin-bottom: 15px;

    li {
      float: left;
      border: none;

      a {
        padding: 0;
      }
    }
  }
}

/* --- Header-Bar --- */
.dexAdminLogo {
  height: 46px;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
  a {
    color: #FFF;
  }
  @include bigDesktop {
    height: 48px;

    a h1 {
      margin-top: 3px;
    }
  }
}

#headerLogo {
  h1 {
    font-size: 16px;
    letter-spacing: 4px;
    margin: 0.125em 0;
  }
}

.widget .dexAdminLogo h1 {
  padding: 10px 20px 8px;
}

ul.double {
  margin-right: -4.0726em; /* 1.4em * 290.9%; see header nav ul li a */

  li:nth-child(2n) {
    margin-left: 4.0726em; /* 1.4em * 290.9%; see header nav ul li a */
    margin-top: -46px;

    @include smallTablet {
      margin-top: -39px;
    }
    @include max {
      margin-top: -39px;
    }
  }
}

#user a:hover {
  color: #333;
  background: linear-gradient(to bottom, #fcfcfc 40%, #dddddd 100%);
}

li {
  &.home > a:before {
    content: "H"
  }

  &.todo > a:before {
    content: "T"
  }

  &.calendar > a:before {
    content: "C"
  }

  &.designs > a:before {
    content: "e"
  }

  &.people > a:before {
    content: "U"
  }

  &.presentations > a:before {
    content: "P"
  }

  &.max > a:before {
    content: "x"
  }

  &.mailaccounts > a:before {
    content: "M"
  }

  &.mails > a:before {
    content: "\f8ff"
  }

  &.webmail > a:before {
    content: "W"
  }

  &.domains > a:before {
    content: "D"
  }

  &.tlds > a:before {
    content: "✎"
  }

  &.useroffice > a:before {
    content: "/"
  }

  &.devtodo > a:before {
    content: "t"
  }

  &.bugs > a:before {
    content: "B"
  }

  &.devtools > a:before {
    content: "d"
  }

  &.templates > a:before {
    content: "E"
  }

  &.invoices > a:before {
    content: "$"
  }

  &.newsletter > a:before {
    content: "N"
  }

  &.special1 > a:before {
    content: "S"
  }

  &.special2 > a:before {
    content: "s"
  }

  &.diagnose > a:before {
    content: "ⓘ"
  }

  &.godmode > a:before {
    content: "×"
  }

  &.bizpartner > a:before {
    content: "b"
  }

  &.reflection > a:before {
    content: "r"
  }

  &.contracts > a:before {
    content: "c"
  }

  &.newContracts > a:before {
    content: "n"
  }

  &.newPartner > a:before {
    content: "u"
  }

  &.wpDesigns > a:before {
    content: "w"
  }

  &.acquiseStats > a:before {
    content: "G"
  }

  &.textTemplates > a:before {
    content: "T";
    font-family: "Times New Roman", Times, serif;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0
  }

  &.partnerWeb > a:before {
    content: "^"
  }

  &.contentOrder > a:before {
    content: "✎"
  }
}

.unfinished > a {
  opacity: 0.5;
}

.broken > a:after {
  content: "0";
  color: #a00;
}

.withSubmenu {
  & > a::after {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    content: "";
    height: 3px;
    left: 50%;
    margin-left: -4px;
    position: absolute;
    top: 1.25em;
    width: 8px;
  }

  & > a.active::after {
    background: rgba(0, 0, 0, 0.5);
  }

  &.hover > a::after,
  &:hover > a::after {
    display: none;
  }
}

.impersonatedLogin {
  header #user {
    a.edit_user,
    a.logout {
      color: #f8b500;
    }

    a.edit_user:hover,
    a.logout:hover {
      background: linear-gradient(to bottom, #fbdf93 40%, #f8b500 98%);
      color: #000;
    }
  }
}

#headerSearch {
  width: 200px;
  white-space: nowrap;
  display: inline-block;
  margin: 11px 15px 0 0;
  float: right;
  position: relative;

  #searchField {
    width: 100%;
    border: 0;
  }
}

.clearer {
  clear: both;
}

@import "includes/inputs";

.person_data {
  .street {
    width: 55%;
  }

  .houseNumber {
    margin-left: 1%;
    width: 14%;
  }

  .areaCode,
  .zipCode {
    width: 15%;
  }

  .phoneNumber,
  .city {
    margin-left: 1%;
    width: 54%;
  }

  .web {
    width: 60%;
  }

  .company,
  .email {
    width: 65%;
  }

  select {
    width: 70%;
  }

  #owner,
  #web {
    margin-bottom: 1em;
  }

  p,
  #newPresentationForm p {
    margin-bottom: 0.35em;
  }
}

.person_data label,
#customer_transaction label {
  text-align: right;
  display: block;
  float: left;
  width: 24%;
  padding-top: .6%;
  padding-right: 1%;
}

#customer_service {
  label, #customer_task label {
    width: 15%;
    display: block;
    float: left;
    padding-top: .6%;
  }

  #addservice, #customer_task #addtask {
    margin-left: 15%;
  }
}

#customer_task .currency {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
}

.infWidget-businessPartnerData tr {
  line-height: 2em;
}

@import "includes/table";

button,
.alignCentered {
  text-align: center;
}

/* ---Formular: Template erstellen --- */

#editContractTemplateForm textarea {
  width: 70%;
  resize: none;
}

.padded_content {
  & > .wrapper {
    padding: 2em 0 2em 5%;
  }

  & > .inner_content {
    padding: 1em 1.75em;
  }

  & > .wrapper > .error {
    margin-right: 5%;
  }
}

#calendar {
  text-align: center;
  width: auto;
  margin: 0 auto;
}

strong {
  font-weight: bold
}

.loginbox,
.logoutbox {
  header {
    #dexAdminLogo {
      border: none;
      text-align: center;
      width: 100%;
      height: auto;
    }
  }
}

div.fc-content th.ui-widget-header,
div.fc-content .ui-widget-content {
  padding: 0.35em;
}

.ui-datepicker-calendar tr:hover a {
  text-decoration: none;
}

span.DataTables_sort_icon.css_right.ui-icon {
  float: right;
}

div.dataTables_wrapper,
div.dataTables_wrapper {
  .ui-toolbar {
    padding: 0.6em;

    input,
    select {
      padding: 0.1em;
    }
  }
}

p {
  &.notice,
  &.error,
  &.success {
    padding: 0.35em;
    position: relative;

    a.dialog-close {
      position: absolute;
      right: 10px;
      top: 5px;
      color: #666;

      &:hover {
        color: #999;
      }
    }
  }

  &.notice {
    background-color: #ffffcc;
    border: 1px solid #f2f279;

    &:before {
      content: url(../../images/icons/info.png);
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.error {
    background-color: #ffcccc;
    border: 1px solid #ff8080;

    &:before {
      content: url(../../images/icons/error.png);
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.success {
    background-color: #e3ffcc;
    border: 1px solid #b9ff80;

    &:before {
      content: url(../../images/icons/success.png);
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

section {
  .contactmail {
    display: none;
  }
}

.cleditorMain {
  padding: 0;
  border: 1px solid #DDD;
  margin-bottom: 1em;
}

.dataTables_wrapper {
  .ui-widget-header.fg-toolbar {
    border: none;
    background: transparent;
    color: #000;
    padding: 0 0 5px;
  }
}

/* --- Rechnungen --- */
a.invoice {
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.25em;

  &:before {
    content: url(../../images/icons/doc_pdf.png);
    margin-right: 10px;
    height: 16px;
    position: relative;
    top: 2px;
  }
}

/* --- Toolbar --- */
ul.toolbar {
  a.ui-button,
  a.ui-button {
    padding: 0;
    margin-right: 0;

    span.ui-button-text {
      float: right;
    }
  }

  li {
    a {
      @mixin icon($selector, $path) {
        &#{$selector}:before {
          content: url($path);
          position: relative;
          top: .25rem;
          left: .375rem;
        }
      }

      @include icon('.email', '../../images/icons/email.png');
      @include icon('.create', '../../images/icons/plus.png');
      @include icon('#createNewAppointmentBtn', '../../images/icons/plus.png');
      @include icon('.edit', '../../images/icons/edit.png');
      @include icon('.present', '../../images/icons/money_euro.png');
      @include icon('.delete', '../../images/icons/trash_bin.png');
      @include icon('.initialize', '../../images/icons/star.png');
      @include icon('.preview', '../../images/icons/preview.png');
      @include icon('.view', '../../images/icons/view.png');
      @include icon('.view_presentation', '../../images/icons/projection_screen.png');
    }

    a.newcontact:before {
      content: url(../../images/icons/newcontact.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.accept:before, ul.toolbar li a.status:before {
      content: url(../../images/icons/check.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.reject:before {
      content: url(../../images/icons/busy.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.revised:before {
      content: url(../../images/icons/finished-work.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.calendar_new:before {
      content: url(../../images/icons/clock_add.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.calendar_edit:before {
      content: url(../../images/icons/clock_edit.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.calendar_remove:before {
      content: url(../../images/icons/clock_remove.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.calendar_reschedule:before {
      content: url(../../images/icons/clock_go.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_choose:before {
      content: url(../../images/icons/world.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_go:before {
      content: url(../../images/icons/home.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_direct:before, ul.toolbar li a.followup:before {
      content: url(../../images/icons/arrow_right.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_edit:before {
      content: url(../../images/icons/edit.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_assign:before {
      content: url(../../images/icons/connect.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_type:before {
      content: url(../../images/icons/config.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.domain_authcode:before {
      content: url(../../images/icons/world_next.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.website_primarydomain:before {
      content: url(../../images/icons/star.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.website_rename:before {
      content: url(../../images/icons/reply.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.website_wp_path:before {
      content: url(../../images/icons/wpmini-blue.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.login:before {
      content: url(../../images/icons/login.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.impersonate:before {
      content: url(../../images/icons/user_silhouette.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.deactivate:before {
      content: url(../../images/icons/lock_lock.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.activate:before {
      content: url(../../images/icons/lock_unlock.png);
      position: relative;
      top: 4px;
      left: 6px;
    }

    a.import:before {
      content: url(../../images/icons/disk.png);
      position: relative;
      top: 4px;
      left: 6px;
    }
  }
}

div {
  &.toolbar_wrapper {
    padding: 0.25em 0;
    border-bottom: 1px solid #aaa;

    ul li {
      a,
      a.ui-state-default,
      a.deactivated:hover,
      a.deactivated:active,
      a.deactivated:focus {
        background: transparent;
        border: 1px solid transparent;
      }
    }
  }

  /* --- Optionbar --- */
  &.optionbar {
    background: #FFF;
    border: 1px solid #AAA;
    padding: 0.5em;
    margin-bottom: 1em;

    & > p {
      margin-right: 1em;
      display: inline;
      font-weight: bold;
    }

    form {
      display: inline-block;
    }

    select {
      padding: 0.25em;
    }

    input[type=text] {
      padding: 0.35em;
    }

    input[type=checkbox] {
      border: 0;
      padding: 0;
      display: inline-block;
      vertical-align: text-bottom;
      margin: 0 0.35em 0 1.5em;
    }

    input[type=checkbox]:first-child {
      margin-left: 0;
    }

    .buttonset {
      .ui-button-text,
      .ui-button {
        padding-left: 0;
        padding-right: 0;
      }
    }

    a.toggleOptions {
      margin-left: .5em;
    }

    .extendedOptions {
      padding: 1em 0 0.5em;
    }

    .extendedOptions.visible {
      display: block;
    }
  }

  &.ui-dialog {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  }
}

/*--- Progress-Bar --- */
.progressbar {
  position: relative;
  margin-bottom: 1em;

  .progressbar_text {
    position: absolute;
    left: 48%;
    top: 20%;
    color: #888;
  }
}

/*--- Popup-Dialog --- */
.dialog {
  display: none;
}

.broad_button {
  padding: 0 4em;
}

/*--- AGB --- */

.agb {
  .secNum {
    font-weight: bold;
  }

  .padded_content ul li {
    list-style: square;
    margin-left: 2.5em;
    margin-bottom: .5em;
    line-height: 1.5em;
  }
}

/*--- Impressum --- */
.impressum {
  dl {
    font-size: 1em;
    line-height: 1.5em;
  }

  dd {
    margin-left: 0;
  }

  dt {
    margin-top: 1em;
    font-weight: bold;
  }
}

/*--- diverses --- */

.ui-state-error,
.hiddenTime {
  display: none;
}

.loader {
  background: url(../../images/office/ajax-loader.gif);
  width: 16px;
  height: 16px;
  clear: both;
  margin: 0 auto;
}

.confirmationTarget {
  font-weight: bold;
}

.small {
  font-size: 85%;
}

.toggleDetails {
  position: relative;

  &:before {
    position: absolute;
    left: -1.5em;
    content: '▶';
    text-decoration: none;
    transition: transform 0.25s ease 0s;
    transform: rotate(90deg);

    transform-origin: 50% 66%;
  }

  &.collapsed:before {
    transform: rotate(0deg);
  }
}

.buttons {
  margin: 1.5em 2em 0;
  text-align: right;
}

.dialog-info-popup .ui-dialog-content:before {
  font-family: IcoMoon, sans-serif;
  font-size: 700%;
  position: absolute;
  right: 0.05em;
  top: 0.4em;
  z-index: -1;
}

.dialog-error .ui-dialog-content:before {
  content: "×";
  color: rgba(255, 0, 0, 0.33);
  top: 0.45em;
}

.dialog-info .ui-dialog-content:before {
  content: "ⓘ";
  font-size: 700%;
  color: rgba(0, 0, 255, 0.15);
}

.dialog-success .ui-dialog-content:before {
  content: "T";
  color: rgba(0, 128, 0, 0.33);
  top: 0.45em;
}

.dialog-warning .ui-dialog-content:before {
  content: "⚠";
  color: rgba(255, 128, 0, 0.44);
}

.dialog-wait .ui-dialog-content:before {
  content: "⌛";
  color: rgba(192, 128, 0, 0.44);
}

.dialog-question .ui-dialog-content:before {
  content: "❓";
  color: rgba(192, 128, 0, 0.33);
}

.throbberOverlay {
  z-index: 900;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.throbberOverlay::before,
.throbber::before,
.personList.fetching::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border-style: solid;
  border-width: 3px;
  border-color: #000 #000 rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);

  border-radius: 100%;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Text in Tooltips */
.ui-tooltip-dark {
  line-height: 1.5em;
}

.dueInfo {
  h1 {
    padding: 0;
    margin: 0;
  }

  p {
    position: relative;
  }

  .okayButton {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.contractName {
  font-weight: bold;
}

/* Personenliste */
.personList {
  width: 20em;
  display: inline-block;

  &.fetching::after {
    margin: 0;
    top: 15%;
    left: auto;
    right: 2.5em;
  }

  .col1, .col2, .col3 {
    padding: 0.25em;
    line-height: 1;
  }

  .col1 {
    .fb-additional,
    .fb-nobody {
      display: none;
    }
  }

  .col2 {
    font-size: 87.5%;
    padding-left: 1em;

    &.fb-nobody {
      text-align: center;
      font-size: 100%;
    }
  }

  .col3 {
    float: right;
  }
}

/* Diagnose */
.browserInfo {
  margin-top: 150px !important;

  button {
    clear: both;
    margin: 1em auto 0;
    display: block;
  }

  dl {
    position: relative;
  }

  dt {
    clear: left;
    float: left;
    width: 12em;
  }

  dd {
    float: left;
    width: 35em;
    margin-bottom: 1em;
    margin-left: 0;
  }

  #sendError,
  #sendSuccess {
    clear: both;
    opacity: 0;
  }

  #sendSuccess {
    display: none;
  }
}

/* Godmode */
.godmode #user {
  a, p {
    color: red;
    border-color: red;
  }

  a.logout::before {
    content: "×";
  }
}

/* Throbber */
.throbber {
  display: inline-block;
  visibility: hidden;
  width: 16px;
  height: 16px;

  &::before {
    margin: -16px 0 0 -16px;
  }
}

.searchform {
  input {
    vertical-align: top;
    height: 2.1em;
    margin: 0;


    box-sizing: border-box;
  }

  .throbber {
    width: 1em;
    height: 0.6em;
    margin-left: -1em;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: bottom;
    position: relative;
    z-index: 2;
  }

  #searchField {
    width: 79.5%;
    padding-top: 0;
    padding-bottom: 0;
  }

  #submitSearch {
    width: 20%;
    margin: 0;
    padding: 0;
    border-left: none;
  }

  #cancelSearch {
    width: 2em;
    color: #700;
    display: none;
    margin: 0 0 0 -2em;
    padding: 0;
    border-color: #999 #999 #999 #CCCCCC;
    border-radius: 2px 0 0 2px;

    &:focus {
      background-color: #FDD;
      border-color: #DBB #DBB #DBB #CCCCCC;
    }
  }

  &.searching #submitSearch,
  &.found #submitSearch {
    width: 15%;
  }

  &.found #cancelSearch {
    display: inline-block;
  }

  &.searching .throbber {
    visibility: visible;
  }
}

.searchError {
  background: #eee;
  color: #000;
  padding: 0.5em 1em 0.75em;
}

tr {
  .correction-for-design {
    background-color: #08C070;

    &.odd {
      background-color: #09DA7F;
    }
  }
}

.namelessCompany {
  font-style: italic;
}

.ui-widget-content .mail-preview a {
  color: blue;
}

.countTag {
  font-size: 50%;
  display: inline-block;
  position: absolute;
  bottom: 0.25em;
  right: 0.25em;
  border-radius: 1em;
  padding: 0 0.2em 0.1em;
  min-width: 1em;
  color: #333;
  background: linear-gradient(to bottom, #fcfcfc 40%, #dddddd 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

  &.empty {
    display: none;
  }
}

a:hover .countTag,
.active .countTag {
  color: #fff;
  background: linear-gradient(to bottom, #111111 0%, #222222 10%);
}

.hasDatepicker {
  background-image: url("../../images/office/calendar2.svg");
  background-position: 99.5% center;
  background-repeat: no-repeat;
  background-size: 2em auto;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  background-color: #eee;
  margin-top: -1px;
  padding-bottom: 1px;
  transform: translateY(1px);
}

.dataTables_scrollBody {
  position: relative;
}

.fc-button {
  .ui-state-active {
    background-color: #bbb;
  }

  .ui-state-active:hover {
    background-color: #aaa;
  }
}

.mailPreviewList {
  display: inline-block;
  vertical-align: top;
}

/* Selectbox stuff */
/* applied to original <select> to hide */
.has_sb {
  position: absolute;
  left: -999999em;
  text-indent: -999999em;
}

/* dropdown styles */
@import "includes/selectbox";

.selectTitle,
.selectDescription {
  line-height: 110%
}

.selectTitle {
  font-weight: bold;
}

.selectDescription {
  opacity: 0.75
}

.loginbox h1 {
  line-height: 1.75em;
  font-family: Times, "Times New Roman", serif;
  font-variant: small-caps;
  letter-spacing: 0.2em;
}

.widget header h3 {
  margin-top: 0;
}

.description {
  @include smallTablet {
    width: 10em;
  }

  @include max {
    position: absolute;
    top: -9999px;
  }
}

@include smallTablet {
  body {
    font-size: 9px;
  }

  #wrapper header,
  footer {
    min-width: 0;
  }

  #wrapper header,
  header nav ul li,
  header nav ul li a,
  header #user .logout {
    height: 39px;
  }

  .dexAdminLogo {
    margin-top: -3px;
    height: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  #headerLogo {
    display: none;
  }

  #footerLogo {
    display: block;
  }

  header #headerSearch {
    display: none;
  }
}

@include bigTablet {
  body {
    font-size: 9.5px;
  }

  #wrapper header,
  header nav ul li,
  header nav ul li a,
  header #user .logout {
    height: 3rem;
  }

  .dexAdminLogo {
    margin-top: -2px;
    height: 42px;
  }

  ul.double li:nth-child(2n) {
    margin-top: -40px;
  }
}

@include smallDesktop {
  body {
    font-size: 10px;
  }

  .dexAdminLogo {
    margin-top: -1px;
    height: 43px;
  }

  ul.double li:nth-child(2n) {
    margin-top: -42px;
  }
}

@include bigDesktop {
  ul.double li:nth-child(2n) {
    margin-top: -48px;
  }
}

@include max {
  #main section.widget,
  header #headerSearch {
    display: none;
  }
}
