/* --- Fonts --- */

@font-face {
  font-family: 'IcoMoon';
  src: url('../../fonts/IcoMoon/IcoMoon.eot');
  src: url('../../fonts/IcoMoon/IcoMoon.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/IcoMoon/IcoMoon.woff') format('woff'),
  url('../../fonts/IcoMoon/IcoMoon.ttf') format('truetype'),
  url('../../fonts/IcoMoon/IcoMoon.svg#IcoMoon') format('svg');
  font-weight: normal;
  font-style: normal;
}