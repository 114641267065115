@import "mixins/mediaQueries";

footer {
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  position: relative;

  bottom: 0;

  @include max {
    min-width: 0;
  }

  width: 100%;
  z-index: 1000;
  background: linear-gradient(to bottom, #333 40%, #000 100%);

  @include mobile {
    flex-direction: column;
  }

  nav {
    position: relative;

    ul li {
      display: inline-block;
      padding: 0 0.8em;
    }

    a {
      color: #FFF;
      line-height: 36px;
    }
  }

  .dexAdminLogo#footerLogo {
      display: none;
      position: relative;
      border-right: none;
      height: 100%;

      h1 {
        padding-top: 1rem;
        padding-bottom: 1rem;

        font-size: 16px;
        letter-spacing: 4px;
        margin: 0.125em 0;
      }
  }
}