/* --- Animations --- */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}